<template>
	<div class="page">
		
		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="服务器1">
				<el-input class="el_input" v-model="form.ser1" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="服务器2">
				<el-input class="el_input" v-model="form.ser2" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="ser_exchange">对调</el-button>
				<el-button type="primary" @click="ser">查询</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="表名" prop="tab_name"></el-table-column>
				<el-table-column label="字段名" prop="name" width="350px"></el-table-column>
				<el-table-column label="比对结果" prop="mark"></el-table-column>
				<el-table-column label="字段数据类型">
					<template slot-scope="scope">
						<div>服务器1: {{scope.row.type.ser1}}</div>
						<div>服务器2: {{scope.row.type.ser2}}</div>
					</template>
				</el-table-column>
				<el-table-column label="字段默认值">
					<template slot-scope="scope">
						<div>服务器1: {{scope.row.default.ser1}}</div>
						<div>服务器2: {{scope.row.default.ser2}}</div>
					</template>
				</el-table-column>
				<el-table-column label="字段备注" width="750px">
					<template slot-scope="scope">
						<div>服务器1: {{scope.row.comment.ser1}}</div>
						<div>服务器2: {{scope.row.comment.ser2}}</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {

				//表单
				form:{
					ser1:'https://jgapi.why.ski',//服务器1
					ser2:'https://devapi.why.ski',//服务器2
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
				},

				//数据结构搜索结果
				dbs:{
					ser1:{},
					ser2:{},
				}
			}
		},
		created() {
			
		},
		methods: {

			//对调
			ser_exchange(){

				let buf1=this.form.ser1
				let buf2=this.form.ser2

				this.form.ser1=buf2
				this.form.ser2=buf1
			},

			//比对
			comparison(){
				let rel=[];
				for(var a in this.dbs.ser2){//以服务器2为蓝本
					var tab_in_ser2=this.dbs.ser2[a];//服务器2数据库某表
					var tab_in_ser1=this.dbs.ser1[a];//服务器1对应的表
					if(!tab_in_ser1){//服务器1没有这个表
						rel.push({
							tab_name:a,
							name:'',
							type:{
								ser1:"无",
								ser2:"无",
							},
							default:{
								ser1:"无",
								ser2:"无",
							},
							comment:{
								ser1:"无",
								ser2:"无",
							},
							mark:'服务器1不存在此表',
						});
					}else{
						for(var b in tab_in_ser2){
							let ziduan_in_tab2=tab_in_ser2[b];//服务器2数据库某表下的某个字段
							let ziduan_in_tab1=tab_in_ser1[b];//服务器1对应的表的字段
							if(!ziduan_in_tab1){//服务器1某个表没有这个字段
								rel.push({
									tab_name:a,
									name:b,
									type:{
										ser1:'无',
										ser2:ziduan_in_tab2.type
									},
									default:{
										ser1:'无',
										ser2:ziduan_in_tab2.default
									},
									comment:{
										ser1:'无',
										ser2:ziduan_in_tab2.comment
									},
									mark:'服务器1不存在此字段',
								});
							}else{
								var mark="";
								var swh=false;
								if(ziduan_in_tab1.type!=ziduan_in_tab2.type){
									swh=true;
									mark+="两方此字段类型不同 ";
								}
								if((ziduan_in_tab1.default != ziduan_in_tab2.default) && (ziduan_in_tab1.default || ziduan_in_tab2.default)){
									swh=true;
									mark+=" 两方此字段默认值不同";
								}
								if((ziduan_in_tab1.comment != ziduan_in_tab2.comment) && (ziduan_in_tab1.comment || ziduan_in_tab2.comment)){
									swh=true;
									mark+=" 两方此字段备注不同";
								}
								if(swh){
									rel.push({
										tab_name:a,
										name:b,
										type:{
											ser1:ziduan_in_tab1.type,
											ser2:ziduan_in_tab2.type
										},
										default:{
											ser1:ziduan_in_tab1.default,
											ser2:ziduan_in_tab2.default
										},
										comment:{
											ser1:ziduan_in_tab1.comment,
											ser2:ziduan_in_tab2.comment
										},
										mark:mark,
									});
								}
							}
						}
					}
				}

				//停止loading
				this.list.loading=false

				//渲染
				this.list.data=rel
			},
			
			//搜索
			ser(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取服务器1数据库字段信息
				let ser1_loaded=false;
				this.$my.net.req({
					api:this.form.ser1,
					data:{
						mod:'res',
						ctr:'get_main_db_info',
					},
					callback:(data)=>{

						//服务器1加载完成
						ser1_loaded=true;

						//置入数据
						this.dbs.ser1=data

						//如果两个服务器都结束加载,则加载完毕
						if(ser1_loaded && ser2_loaded){

							//开始比对
							this.comparison();
						}
					}
				})

				//读取服务器2数据库字段信息
				let ser2_loaded=false;
				this.$my.net.req({
					api:this.form.ser2,
					data:{
						mod:'res',
						ctr:'get_main_db_info',
					},
					callback:(data)=>{

						//服务器2加载完成
						ser2_loaded=true;

						//置入数据
						this.dbs.ser2=data

						//如果两个服务器都结束加载,则加载完毕
						if(ser1_loaded && ser2_loaded){

							//开始比对
							this.comparison();
						}
					}
				})
			},
		}
	}
</script>
<style lang="scss" scoped>

	//弹出层
	.in_tab_img_item{
		width: 300px;
	}

	//用户修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}
	
	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 60px);
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>